<template>
    <o-data-lookup :data-object="dsLookupDataObject" :whereClause="getWhereClause" :forceReloadOnOpen="true" :disableRecent="true">
        <template #toolbarActions>
            <slot name="toolbarActions"></slot>
        </template>

        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.slotname" :ref="scope.target" style="cursor: pointer;">
                <slot name="slotname"></slot>
            </span>
        </template>   
        
        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="250"></o-column> 
        <o-column field="Description" width="250"></o-column> 
        <o-column field="OrgUnit" width="250"></o-column> 
    </o-data-lookup>
</template>

<script setup>
    import { computed } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { context } from 'o365-modules';

    const props = defineProps({
        is: String,   
        textInputValue: String,
        textInput: Boolean,
        MetaField: {
            type: String,
            required: true
        },
        DomainID:  {
            type: Number,
            required: false
        }   
    });

    const dsLookupDataObject = getOrCreateDataObject({
        id: 'dsLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Assets_MetaObjects',
        maxRecords: 25,
        whereClause: "1=2",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "string" },
              {name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" },
              {name: "Description", type: "string" },
              {name: "OrgUnit", type: "string" }]
    });
 
    const metaProp = computed(() => props.MetaField);
    const DomainID = computed(() => props.DomainID);

    const getWhereClause = computed( () => {
        var vFilter = [];

        if (metaProp.value) {
            vFilter.push("MetaType='" + metaProp.value + "'")
            
            if (props.DomainID) {
                vFilter.push("Domain_ID = " + props.DomainID);
            }   
            else if(context.domainId) {
                vFilter.push("Domain_ID = " + context.domainId); 
            }
            else {
                vFilter.push("1=3");
                //alert($t(`No Domain`), ToastType.Warning, { autohide: true, delay: 3000 })
            }
        }
        else {
            vFilter.push("1=2");
        }

        return vFilter.join(" AND ");
    });
   
</script>